<template>
    <section class=".container">
        <Header />
        <main class="banner">
            <div>
                <p>development</p>
            </div>
            <h1>
                Barrio Alegre
            </h1>
            <p>
                Conectando la esencia de la marca a una tienda con un concepto único.
            </p>
        </main>
        <section class="parallax">
            <div></div>
        </section>
        <section class="content">
            <div>
                <p>El reto</p>
                <h2>
                    Nuestro reto en desarrollo
                </h2>
                <p>
                    Comenzando por que la página estaba diseñada en WordPress, lenta y con bastantes problemas de
                    seguridad pues el sitio se infectaba muy seguido con MalWare. <br>
                    Procedimos a emigrarla a un lenguaje más moderno, seguro y que nos brindará un mejor rendimiento.
                </p>
            </div>
            <div>
                <picture>
                    <source srcset="../../assets/proyectos/barrioalegre/content_img.webp" type="image/webp">
                    <img src="../../assets/proyectos/barrioalegre/content_img.png" alt="Imagen decorativa"
                        loading="lazy">
                </picture>
            </div>
        </section>
        <section class="video">
            <iframe class="only-pc"
                src="https://www.youtube.com/embed/8_AEd4ljBUM?si=QY4wxhrRC2P7xiag&autoplay=1&mute=1&loop=1&playlist=8_AEd4ljBUM&showinfo=0&controls=0"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            <iframe class="only-mov"
                src="https://www.youtube.com/embed/MwK7pFTHsTQ?si=QY4wxhrRC2P7xiag&autoplay=1&mute=1&loop=1&playlist=MwK7pFTHsTQ&showinfo=0&controls=0"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
        </section>
        <section class="pallete">
            <h2>Color Palette</h2>
            <div>
                <p>French Pink</p>
                <h3>#F967A3</h3>
            </div>
            <div>
                <p>Titanium Yellow</p>
                <h3>#F3E60F</h3>
            </div>
            <div>
                <p>Han Blue</p>
                <h3>#3968C5</h3>
            </div>
            <div>
                <p>Electric Blue</p>
                <h3>#80F9F3 </h3>
            </div>
        </section>
        <section class="mosaico__video">
            <picture class="only-pc">
                <source srcset="../../assets/proyectos/barrioalegre/mosaico_1_pc.webp" type="image/webp">
                <img src="../../assets/proyectos/barrioalegre/mosaico_1_pc.png" alt="Imagen de barrio alegre"
                    loading="lazy">
            </picture>
            <picture class="only-mov">
                <source srcset="../../assets/proyectos/barrioalegre/mosaico_1_mov.webp" type="image/webp">
                <img src="../../assets/proyectos/barrioalegre/mosaico_1_mov.png" alt="Imagen de barrio alegre"
                    loading="lazy">
            </picture>
        </section>
        <section class="mosaico">
            <div class="video__uno">
                <iframe
                    src="https://www.youtube.com/embed/u1r2Eu2XI8s?si=QY4wxhrRC2P7xiag&autoplay=1&mute=1&loop=1&playlist=u1r2Eu2XI8s&showinfo=0&controls=0"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen></iframe>
            </div>
            <div class="mosaico__stack">
                <picture class="only-pc">
                    <source srcset="../../assets/proyectos/barrioalegre/galeria_stack_1.webp" type="image/webp">
                    <img src="../../assets/proyectos/barrioalegre/galeria_stack_1.png" alt="Prueba de barrio alegre"
                        loading="lazy">
                </picture>
                <picture class="only-pc">
                    <source srcset="../../assets/proyectos/barrioalegre/galeria_stack_2.webp" type="image/webp">
                    <img src="../../assets/proyectos/barrioalegre/galeria_stack_2.png" alt="Prueba de barrio alegre"
                        loading="lazy">
                </picture>
                <picture class="only-pc">
                    <source srcset="../../assets/proyectos/barrioalegre/galeria_stack_3.webp" type="image/webp">
                    <img src="../../assets/proyectos/barrioalegre/galeria_stack_3.png" alt="Prueba de barrio alegre"
                        loading="lazy">
                </picture>
                <picture class="only-mov">
                    <source srcset="../../assets/proyectos/barrioalegre/galeria_stack_4.webp" type="image/webp">
                    <img src="../../assets/proyectos/barrioalegre/galeria_stack_4.png" alt="Prueba de barrio alegre"
                        loading="lazy">
                </picture>
                <picture class="only-mov">
                    <source srcset="../../assets/proyectos/barrioalegre/galeria_stack_5.webp" type="image/webp">
                    <img src="../../assets/proyectos/barrioalegre/galeria_stack_5.png" alt="Prueba de barrio alegre"
                        loading="lazy">
                </picture>
                <picture class="only-mov">
                    <source srcset="../../assets/proyectos/barrioalegre/galeria_stack_6.webp" type="image/webp">
                    <img src="../../assets/proyectos/barrioalegre/galeria_stack_6.png" alt="Prueba de barrio alegre"
                        loading="lazy">
                </picture>
            </div>
        </section>
        <section class="last__content">
            <h2>Concepto y diseño
            </h2>
            <p>
                Logramos capturar la escencia de la marca con un estilo kitsch formado en base a collages y surrealismo,
                elementos y estilos que se usa frecuentemente la marca en redes sociales. Usamos una paleta de colores
                acorde a los fundamentos del branding, siendo el rojo y el beige los tonos principales, también
                empleamos el uso de líneas delgadas que creó un balance entre las imagenes cargadas y el flujo
                minimalista. Se destacó mucho la visión e imaginación del creador junto con su personalidad.
            </p>
        </section>
        <section class="slider__wrapper">

            <div class="slider only-mov" @scroll="buttonBlock()">
                <picture>
                    <source srcset="../../assets/proyectos/barrioalegre/slider_1.webp" type="image/webp">
                    <img src="../../assets/proyectos/barrioalegre/slider_1.png" alt="slide 1">
                </picture>
                <picture>
                    <source srcset="../../assets/proyectos/barrioalegre/slider_2.webp" type="image/webp">
                    <img src="../../assets/proyectos/barrioalegre/slider_2.png" alt="slide 2">
                </picture>
                <picture>
                    <source srcset="../../assets/proyectos/barrioalegre/slider_3.webp" type="image/webp">
                    <img src="../../assets/proyectos/barrioalegre/slider_3.png" alt="slide 3">
                </picture>
            </div>

            <button class="only-mov" @click="slide(false)">
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0 0 6 9" fill="none">
                    <g clip-path="url(#clip0_1_960)">
                        <path
                            d="M4.07827 4.79985C4.85973 5.41911 5.62441 6.02559 6.38949 6.6317C6.82737 6.97846 7.26804 7.32131 7.70192 7.67162C8.06988 7.96899 8.09904 8.43833 7.77902 8.75133C7.465 9.05865 6.94003 9.08779 6.57647 8.80143C5.16097 7.68654 3.74786 6.56917 2.33875 5.44825C1.86892 5.07449 1.89129 4.50283 2.3827 4.11238C3.20651 3.45723 4.03392 2.80564 4.85933 2.15262C5.42625 1.7039 5.98917 1.25127 6.56089 0.807161C7.07427 0.408176 7.83376 0.614596 7.97599 1.18945C8.0495 1.48647 7.94403 1.73659 7.68793 1.93874C6.53372 2.85147 5.3803 3.76526 4.22689 4.67906C4.18294 4.71387 4.13979 4.74976 4.07827 4.79985Z"
                            fill="#987EF3" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1_960">
                            <rect width="6" height="8.4" fill="white" transform="translate(6 9) rotate(180)" />
                        </clipPath>
                    </defs>
                </svg>
            </button>
            <button class="only-mov" @click="slide(true)">
                <svg xmlns="http://www.w3.org/2000/svg" width="6" height="9" viewBox="0 0 6 9" fill="none">
                    <g clip-path="url(#clip0_1_960)">
                        <path
                            d="M4.07827 4.79985C4.85973 5.41911 5.62441 6.02559 6.38949 6.6317C6.82737 6.97846 7.26804 7.32131 7.70192 7.67162C8.06988 7.96899 8.09904 8.43833 7.77902 8.75133C7.465 9.05865 6.94003 9.08779 6.57647 8.80143C5.16097 7.68654 3.74786 6.56917 2.33875 5.44825C1.86892 5.07449 1.89129 4.50283 2.3827 4.11238C3.20651 3.45723 4.03392 2.80564 4.85933 2.15262C5.42625 1.7039 5.98917 1.25127 6.56089 0.807161C7.07427 0.408176 7.83376 0.614596 7.97599 1.18945C8.0495 1.48647 7.94403 1.73659 7.68793 1.93874C6.53372 2.85147 5.3803 3.76526 4.22689 4.67906C4.18294 4.71387 4.13979 4.74976 4.07827 4.79985Z"
                            fill="#987EF3" />
                    </g>
                    <defs>
                        <clipPath id="clip0_1_960">
                            <rect width="6" height="8.4" fill="white" transform="translate(6 9) rotate(180)" />
                        </clipPath>
                    </defs>
                </svg>
            </button>

            <picture class="only-pc">
                <source srcset="../../assets/proyectos/barrioalegre/slider_conjunto.webp" type="image/webp">
                <img src="../../assets/proyectos/barrioalegre/slider_conjunto.png" alt="Imagen conjunto">
            </picture>
        </section>
        <Footer />
    </section>
</template>
<script>
import Footer from '../../components/on-site/Footer.vue';
import Header from '../../components/on-site/Header.vue';

export default {
    components: { Footer, Header },

    data() {
        return {
            pantalla: null,
        }
    },

    mounted() {
        window.addEventListener('scroll', function () {
            if (this.window.innerWidth >= 768) return ''

            const { height } = this.document.querySelector('.banner').getBoundingClientRect();
            const heightParallax = this.document.querySelector('.parallax').getBoundingClientRect().height;
            const parallaxPicture = document.querySelector('.parallax>div');
            const scrollPositionY = window.scrollY;

            // Define el rango de la animación según sea necesario
            const minRangeY = height; // Ajusta según sea necesario
            const maxRangeY = heightParallax; // Ajusta según sea necesario
            const tope = 100;

            // Calcula el porcentaje de desplazamiento dentro del rango
            const percentageY = (maxRangeY - minRangeY) / tope

            const factor = (93 / (1 + 173.39 * Math.E ** (-0.0062 * this.window.innerHeight)));

            const cantidadScroll = percentageY * (scrollPositionY - minRangeY) / factor

            if (scrollPositionY > minRangeY || scrollPositionY <= maxRangeY) {
                parallaxPicture.style.setProperty('--traslado', `${cantidadScroll}%`);
            }

            // && height < scrollPositionY - minRangeY
            if ((scrollPositionY >= maxRangeY && heightParallax < scrollPositionY - minRangeY) || cantidadScroll >= tope) {
                parallaxPicture.style.setProperty('--traslado', `${tope}%`);
            }

            if (scrollPositionY < minRangeY) {
                parallaxPicture.style.setProperty('--traslado', `0%`);
            }
        })

        window.addEventListener('resize', function () {
            const parallaxPicture = document.querySelector('.parallax>picture.only-mov');

            if (window.innerWidth >= 768 && this.pantalla != 'pc') {
                this.pantalla = "pc";
            } else if (window.innerWidth < 768 && this.pantalla != 'movil') {
                parallaxPicture.style.transform = `translateX(0%)`;
                resetMounted();
                this.pantalla = "movil";
                window.scroll({
                    top: 0,
                    left: 0,
                    behavior: "instant",
                });
            }

        })

        let resetMounted = () => {
            this.slideReset()
        }


        if (window.innerWidth >= 768) this.pantalla = "pc";
        else if (window.innerWidth < 768) this.pantalla = "movil";
        resetMounted();
        this.buttonBlock();
    },

    methods: {
        slide(parametro) {
            const slider = document.querySelector('.slider');
            const slide = document.querySelector('.slider > picture').getBoundingClientRect().width;
            if (parametro) {
                slider.scrollLeft += slide;
            } else {
                slider.scrollLeft -= slide;
            }
        },

        buttonBlock() {
            const slider = document.querySelector('.slider');
            const buttons = document.querySelectorAll('.slider__wrapper > button');
            if (!slider) return '';

            if (slider.scrollLeft === 0) {
                buttons[0].classList.add("bloqueado");
            } else if (Math.ceil(slider.scrollLeft) === slider.scrollWidth - slider.clientWidth) {
                buttons[1].classList.add("bloqueado");
            } else {
                buttons.forEach(button => {
                    button.classList.remove("bloqueado");
                });
            }
        },

        slideReset() {
            if (this.pantalla != "movil") return '';
            const slider = document.querySelector(".slider");

            setTimeout(() => {
                slider.scroll({
                    top: 0,
                    left: 0,
                    behavior: "instant"
                })
            }, 100);
        },
    },
}


</script>
<style scoped>
*,
*::before,
*::after {
    box-sizing: border-box;
}

h1,
h2,
h3,
p {
    margin: 0;
}

.bloqueado {
    opacity: 0;
}

picture {
    display: flex !important;
    width: fit-content;
    height: fit-content;
}

.container {
    display: grid;
}

.only-pc {
    display: none !important;
}

.banner {
    background-size: 100% 130.43478260869566VW;
    background-repeat: no-repeat;
    background-color: #000;
    background-position: 0 84.29951690821255VW;

    background-image: url("../../assets/proyectos/barrioalegre/banner_mov.png");
    background-image: url("../../assets/proyectos/barrioalegre/banner_mov.webp");
    padding: 33.091787439613526VW 8.222222VW 149.7584541062802VW 9.66183574879227VW;
}

.banner div {
    background-color: #3702FB;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 21.014492753623188VW;
    height: 4.830917874396135VW;
}

.banner div p {
    color: #FFF;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;

    font-size: 1.932367149758454VW;
    line-height: 135.8%;
}

h1 {
    color: #FFF;
    font-family: Gramatika-Medium;
    font-style: normal;
    font-weight: 400;

    font-size: 19.32367149758454VW;
    line-height: 121.3%;
    margin: 2.657004830917874VW 0 2.898550724637681VW;
}

.banner>p {
    color: #FFF;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;

    font-size: 2.657004830917874VW;
    line-height: 149.5%;
}

.parallax {
    height: 190lvh;
    margin: 0;
}

.parallax>div {
    --traslado: 0%;
    width: 100%;
    height: 100lvh;
    background-image: url("../../assets/proyectos/barrioalegre/parallax.png");
    background-image: url("../../assets/proyectos/barrioalegre/parallax.webp");
    background-size: auto 100lvh;
    background-repeat: no-repeat;
    background-position-x: var(--traslado);

    position: sticky;
    top: 0;
}

.content {
    padding: 12.318840579710146VW 0;
}

.content>div:first-of-type {
    padding: 0 11.11111111111111VW;
}

.content p:first-of-type {
    color: #EF67C2;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;

    font-size: 2.657004830917874VW;
    line-height: 125%;
    margin: 0 0 8.937198067632849VW;
}

.content h2 {
    color: #000;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;

    font-size: 5.314009661835748VW;
    line-height: 125%;
    padding: 0 7.246376811594203VW 7.246376811594203VW 0;
}

.content p:last-of-type {
    color: #231F20;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;

    font-size: 2.657004830917874VW;
    line-height: 190.5%;
    padding: 0 7.246376811594203VW 10.38647342995169VW 0;
}

.content picture {
    margin: 0 0 0 4.5893719806763285VW;
}

.content img {
    width: 87.92270531400966VW;
    aspect-ratio: 364/437;
}

.video {
    width: 100%;
    height: 100lvh;
    pointer-events: none;
    overflow: hidden;
}

.video iframe {
    width: 100%;
    height: 100%;
    transform: scale(1.1);
}

.imagen__mov picture {
    width: 100%;
    height: fit-content;
}

.imagen__mov img {
    width: 100%;
    height: 100lvh;
    object-fit: cover;
}

.pallete {
    display: grid;
}

.pallete h2 {
    color: #000;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;
    text-align: center;

    font-size: 5.314009661835748VW;
    line-height: 125%;
    margin: 21.980676328502415VW 0 20.048309178743963VW;
}

.pallete div {
    height: 45.65217391304348VW;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2077294685990339VW 0;
}

.pallete h3 {
    color: #FFF;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;

    font-size: 8.454106280193237VW;
    line-height: 125%;
}

.pallete p {
    color: #FFF;
    text-align: center;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;

    font-size: 4.3478260869565215VW;
    line-height: 125%;
}

.pallete div:nth-of-type(4) h3,
.pallete div:nth-of-type(4) p {
    color: #231F20;
}

.pallete p {
    order: 2;
}

.pallete div:nth-of-type(1) {
    background-color: #F967A3;
}

.pallete div:nth-of-type(2) {
    background-color: #F3E60F;
}

.pallete div:nth-of-type(3) {
    background-color: #3968C5;
}

.pallete div:nth-of-type(4) {
    background-color: #80F9F3;
}

.mosaico__video {
    width: fit-content;
    height: fit-content;
    margin: 21.980676328502415VW auto 0;
}

.mosaico__video img {
    width: 83.81642512077295VW;
    aspect-ratio: 347/501;
}

.mosaico .video__uno {
    width: 83.81642512077295VW;
    aspect-ratio: 347/346;
    margin: 0 auto;
    overflow: hidden;
}

.video__uno iframe {
    width: 100%;
    height: 100%;
    transform: scale(1.4);
}

.mosaico div>* {
    grid-area: stack;
}

.mosaico div {
    width: 83.81642512077295VW;
    height: 62.80193236714976VW;
    display: grid;
    grid-template-areas: "stack";
    background-color: #80F9F3;
    margin: 0 auto;
    position: relative;
}

.mosaico div picture:nth-of-type(4) {
    margin: -15.70048309178744VW 0 0 -7.971014492753622VW;
}

.mosaico div picture:nth-of-type(4) img {
    width: 60.38647342995169VW;
    aspect-ratio: 250/327;
}

.mosaico div picture:nth-of-type(5) {
    margin: 1.4492753623188406VW 0 0 -8.937198067632849VW;
}

.mosaico div picture:nth-of-type(5) img {
    width: 81.15942028985508VW;
    aspect-ratio: 336/389;
}

.mosaico div picture:nth-of-type(6) {
    margin: 15.70048309178744VW 0 0 31.88405797101449VW;
}

.mosaico div picture:nth-of-type(6) img {
    width: 60.38647342995169VW;
    aspect-ratio: 250/298;
}

.last__content {
    padding: 41.54589371980676VW 9.903381642512077VW 21.980676328502415VW;
}

.last__content h2 {
    color: #231F20;
    text-align: center;
    font-family: Gramatika-Bold;
    font-style: normal;
    font-weight: 400;

    font-size: 4.830917874396135VW;
    line-height: 125%;
}

.last__content p {
    color: #231F20;
    text-align: center;
    font-family: "IBM Plex Mono";
    font-style: normal;
    font-weight: 400;

    font-size: 2.657004830917874VW;
    line-height: 190.5%;
    padding: 10.869565217391305VW 3.6231884057971016VW 0;
}

.slider__wrapper {
    overflow: hidden;
    position: relative;
}

.slider {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 89.921256VW;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    margin: 0 4.869565VW;
    column-gap: 4.869565VW;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.slider::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.slider {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.slider picture {
    scroll-snap-align: start;
}

.slider picture img {
    width: 89.82125603864735VW;
    height: auto;
}

.slider__wrapper button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 9.66183574879227VW;
    height: 9.420289855072465VW;
    border-radius: 100%;
    background-color: transparent;
    border: 0.24154589371980675VW solid #987EF3;
    transition: opacity 0.3s ease-out;
}

.slider__wrapper button:nth-of-type(1) {
    left: 5.314009661835748VW;
}

.slider__wrapper button:nth-of-type(2) {
    right: 5.314009661835748VW;
    transform: rotateZ(180deg);
}

button svg {
    width: 1.4492753623188406VW;
    height: 2.1739130434782608VW;
}

@media (min-width: 768px) {

    .only-mov {
        display: none !important;
    }

    .only-pc {
        display: block !important;
    }

    picture.only-pc {
        display: flex !important;
    }

    .banner {
        background-image: url("../../assets/proyectos/barrioalegre/banner_pc.png");
        background-image: url("../../assets/proyectos/barrioalegre/banner_pc.webp");
        background-size: 80.52083333333333VW 51.770833333333336VW;
        background-position: 0 2.2395833333333335VW;
        padding: 20.885416666666668VW 7.46875VW 18.385416666666668VW 63.072916666666664VW;
    }

    .banner div {
        width: 5.9375VW;
        height: 1.3541666666666667VW;
    }

    .banner div p {
        font-size: 0.625VW;
        line-height: 135.8%;
    }

    h1 {
        font-size: 6.770833333333333VW;
        line-height: 121.3%;
        margin: 1.5625VW 0;
    }

    .banner>p {
        font-size: 0.7291666666666666VW;
        line-height: 149.5%;
    }

    .parallax {
        height: fit-content;
        margin: 7.8125VW auto 11.197916666666668VW;
    }

    .parallax>div {
        --traslado: 0;
        width: 83.33333333333334VW;
        height: 37.34375VW;
        background-size: cover;
        animation: none;
        position: static;
        margin: 0 auto;
    }

    .content {
        padding: 0 0 15.46875VW 10.416666666666668VW;
        display: flex;
        column-gap: 8.166667VW;
    }

    .content>div:first-of-type {
        padding: 0;
    }

    .content p:first-of-type {
        font-size: 1.0416666666666665VW;
        line-height: 125%;
        margin: 5.885416666666667VW 0 0;
    }

    .content h2 {
        font-size: 1.8229166666666667VW;
        line-height: 125%;
        padding: 0;
        margin: 2.604166666666667VW 0 5.729166666666666VW;
    }

    .content p:last-of-type {
        width: 32.96875VW;
        font-size: 0.8333333333333334VW;
        line-height: 190.5%;
        padding: 0;
    }

    .content img {
        width: 39.479166666666664VW;
        aspect-ratio: 758/909;
        transform: none;
    }

    .video {
        width: 83.33333333333334VW;
        height: 83.33333333333334VW;
        margin: 0 auto;
    }

    .video iframe {
        transform: scale(1);
    }

    .imagen__mov {
        display: none;
    }

    .pallete {
        grid-template-columns: repeat(4, 1fr);
    }

    .pallete h2 {
        font-size: 1.1458333333333333VW;
        line-height: 125%;
        grid-column: 1 / -1;
        margin: 4.739583333333333VW 0 4.322916666666667VW;
    }

    .pallete div {
        height: 44.895833333333336VW;
        gap: 0.26041666666666663VW 0;
        padding: 0 0 12.760416666666666VW;
        justify-content: center;
        align-items: end;
        flex-direction: row;
    }

    .pallete h3 {
        font-size: 1.8229166666666667VW;
        line-height: 125%;
        order: 2;
        transform: rotate(-180deg);
        writing-mode: vertical-lr;
    }

    .pallete p {
        font-size: 0.9375VW;
        line-height: 125%;
        transform: rotate(-180deg);
        writing-mode: vertical-lr;
    }

    .pallete p {
        order: 1;
    }

    .mosaico__video {
        margin: 21.980676328502415VW auto 0;
    }

    .mosaico__video img {
        width: 88.02083333333334VW;
    }


    .mosaico {
        display: flex;
        width: 88.02083333333334VW;
        margin: 0 auto;
    }

    .mosaico .video__uno {
        margin: 0;
    }

    .mosaico .video__uno {
        width: 43.75VW;
        aspect-ratio: 840/762;
        pointer-events: none;
        overflow: hidden;
    }

    .video__uno iframe {
        transform: scale(1.1);
    }

    .mosaico div {
        width: 44.27083333333333VW;
        height: 39.6875VW;
        margin: 0;
    }

    .mosaico div picture:nth-of-type(1) {
        margin: -8.59375VW 0 0 9.947916666666666VW;
    }

    .mosaico div picture:nth-of-type(1) img {
        width: 26.145833333333336VW;
        aspect-ratio: 502/656;
    }

    .mosaico div picture:nth-of-type(2) {
        margin: 6.979166666666667VW 0 0 2.8125VW;
    }

    .mosaico div picture:nth-of-type(2) img {
        width: 36.614583333333336VW;
        aspect-ratio: 703/648;
    }

    .mosaico div picture:nth-of-type(3) {
        margin: 18.802083333333332VW 0 0 15.572916666666666VW;
    }

    .mosaico div picture:nth-of-type(3) img {
        width: 31.041666666666668VW;
        aspect-ratio: 596/598;
    }

    .last__content {
        padding: 11.302083333333334VW 31.145833333333332VW;
    }

    .last__content h2 {
        font-size: 1.3020833333333335VW;
        line-height: 125%;
    }

    .last__content p {
        font-size: 0.8333333333333334VW;
        line-height: 190.5%;
        padding: 3.3854166666666665VW 1.4583333333333333VW 0;
    }

    .slider__wrapper {
        display: grid;
        justify-items: center;
        padding-bottom: 14.583333333333334VW;
    }

    .slider__wrapper>picture,
    .slider__wrapper>picture img {
        width: 77.29166666666667VW !important;
        aspect-ratio: 1484/897 !important;
    }

    .only-pc img {
        width: 88.02083333333334VW;
        aspect-ratio: 1690/859;
    }
}
</style>